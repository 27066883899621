const fields = [
  { value: 'old', text: 'Indicador de paciente idoso' },
  { value: 'access_number', text: 'AN' },
  { value: 'patient_report', text: 'Prontuário' },
  { value: 'patient', text: 'Paciente' },
  { value: 'order_number', text: 'N° Pedido' },
  { value: 'attendance_date', text: 'Data de Atendimento' },
  { value: 'emp_sigla', text: 'Empresa (Sigla)' },
  { value: 'emp_nome', text: 'Empresa (Nome)' },
  { value: 'source', text: 'Origem' },
  { value: 'medical_report', text: 'Código do Laudo' },
  { value: 'medical_report_id', text: 'Código do Laudo WEB' },
  { value: 'status', text: 'Status' },
  { value: 'exam', text: 'Exame' },
  { value: 'sla', text: 'SLA' },
  { value: 'requester', text: 'Médico Solicitante' },
  { value: 'doctor', text: 'Médico Executante' },
  { value: 'medical_report_date', text: 'Data do Laudo' },
  { value: 'subgroup', text: 'Modalidade' },
  { value: 'pac_icdeficiente', text: 'Indicador de paciente deficiente' },
  { value: 'pac_nomesocial', text: 'Nome Social' },
  { value: 'cor_tipo_atendimento', text: 'Cor tipo de atendimento' },
  { value: 'pac_datanasc', text: 'Data de nascimento' },
]

const orders = [
  { value: 'urgencia', text: 'Urgência' },
  { value: 'sla', text: 'SLA' },
  { value: 'tipo_atendimento', text: 'Tipo de Atendimento' },
]

export default {
  fields: fields,
  orders: orders,
}
