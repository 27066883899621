var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"loading-container pt-12"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1):_vm._e(),_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],ref:"form",staticClass:"max-width",attrs:{"disabled":_vm.$attrs.disabled},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('ErrorMessages',{attrs:{"messages":_vm.errors}}),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":"Fila de Trabalho","items":_vm.lists,"item-value":"lst_isn","item-text":"lst_nome","rules":_vm.$rules.required,"required":"","dense":"","outlined":""},model:{value:(_vm.form.list_isn),callback:function ($$v) {_vm.$set(_vm.form, "list_isn", $$v)},expression:"form.list_isn"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-tabs',{staticClass:"nj-tabs",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Colunas Visíveis")]),_c('v-tab',[_vm._v("Ordem dos registros")])],1),_c('v-tabs-items',{attrs:{"eager":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"py-4",attrs:{"eager":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.visibleFieldsItems,"single-select":false,"item-key":"name","show-select":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.controls",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.moveUp(_vm.visibleFieldsItems, index)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.moveDown(_vm.visibleFieldsItems, index)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}},{key:"item.column",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fieldText")(item.name))+" ")]}}]),model:{value:(_vm.form.fields),callback:function ($$v) {_vm.$set(_vm.form, "fields", $$v)},expression:"form.fields"}})],1),_c('v-tab-item',{staticClass:"py-4",attrs:{"eager":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orderItems,"single-select":false,"item-key":"value","show-select":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.controls",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.moveUp(index)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.moveDown(index)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}},{key:"item.column",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.form.orders),callback:function ($$v) {_vm.$set(_vm.form, "orders", $$v)},expression:"form.orders"}})],1)],1)],1)],1),_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }